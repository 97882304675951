import React from 'react';
import PropTypes from 'prop-types';
import LinkedButton from '../atoms/LinkedButton';
import { getImageUrlFromOffer } from '../misc/renderHelpers';
import ButtonArrow from '../atoms/ButtonArrow';

export default function SliderEvent({ offer, edmBaseUrl, parentUrl }) {
  if (!offer) return null;

  const icon = <ButtonArrow />;

  const { event_base } = offer;
  const { name, event_type_name, slug } = event_base;
  const eventImageUrl = getImageUrlFromOffer(event_base);

  const finalTo = parentUrl ? `${parentUrl}/event?slug=${slug}` : `/event/${slug}`;

  return (
    <div className="event-slider">
      <div className="event-slider__image">
        <img
          src={
            eventImageUrl
              ? `${edmBaseUrl}${eventImageUrl}`
              : 'https://images.unsplash.com/photo-1551434678-e076c223a692?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2850&q=80'
          }
          alt=""
        />
      </div>
      <div className="event-slider__content">
        <div className="event-slider__content__subline">{event_type_name}</div>
        <h2 className="event-slider__content__headline">{name}</h2>
        <div className="event-slider__content__buttons">
          <LinkedButton
            to={finalTo}
            label="Mehr erfahren"
            variant="inverted"
            icon={icon}
            target={parentUrl ? '_top' : '_self'}
          />
        </div>
      </div>
    </div>
  );
}

SliderEvent.propTypes = {
  offer: PropTypes.object.isRequired,
  edmBaseUrl: PropTypes.string.isRequired,
  parentUrl: PropTypes.string,
};
